.footer-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #333;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    color: white;
}
a {
    color: inherit;
}